import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Button from 'react-bootstrap/Button';
import SEO from "../components/seo";
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdBannerGeneral from "../components/adBannerGeneral";

const ImagesContent = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);
    const plusMember = useSelector(state => state.plusMember);

    return (
       <div className="pageWrapper page textPage">
            <SEO 
                title={totalUnread == 0 ? props.intl.formatMessage({ id: 'images' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
                description={props.intl.formatMessage({ id: 'imagesSeoDesc' })}
                pagePath="/images"
            />

            <h1><FormattedMessage id="images" /></h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>
            <br />

            <div className="aboutPage">
                <h3><FormattedMessage id="imageChatForAll" /></h3>

                <p>
                    <FormattedMessage id="imagesSeoDesc" /> <FormattedMessage id="thatWayWeMakeSure" />
                </p>

                 <p>
                    <FormattedMessage id="imageChatLikeOtherRooms" /> <FormattedMessage id="thatWayYouCanVisit" />
                 </p>

                <p><FormattedMessage id="welcomeIn" />!</p>

                <Button as={Link} to={props.langUrl == "" ? "/room/images-sfw" : "/sv/room/bilder-sfw"} variant="info">
                    <FormattedMessage id="visit" /> <FormattedMessage id="images" /> SFW
                </Button>

                <br /><br />

                <Button as={Link} to={props.langUrl == "" ? "/room/images" : "/sv/room/bilder"} variant="info">
                    <FormattedMessage id="visit" /> <FormattedMessage id="images" /> NSFW
                </Button>
                
                <br /><br />
                
                {/*}
                {!plusMember &&
                    <div>
                        <a href="https://track.cam4tracking.com/a8877e92-16f2-4a6e-9662-1c2a43d1398a?SiteID={SiteID}&VAR8=200679&VAR9=dinchat_728x90" 
                                target="_blank">
                            <img className="adBanner" src={ad} alt="" />
                        </a>
                    </div>
                }
                {*/}
                {!plusMember &&
                    <div>
                    {/*}
                        <a href="https://track.cam4tracking.com/a8877e92-16f2-4a6e-9662-1c2a43d1398a?SiteID={SiteID}&VAR8=200679&VAR9=dinchat_728x90" 
                                target="_blank">
                            <img className="adBanner" src={ad} alt="" />
                        </a>
                    {*/}
                        <AdBannerGeneral />
                    </div>
                }
            </div>

        </div>
    )
}

export default injectIntl(ImagesContent);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`